@font-face {
  font-family: Antenna;
  src: url("https://storage.googleapis.com/purina-virtual-experience/design/fonts/Antenna-Regular.otf")
    format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: Antenna;
  src: url("https://storage.googleapis.com/purina-virtual-experience/design/fonts/Antenna-Bold.otf")
    format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: Antenna;
  src: url("https://storage.googleapis.com/purina-virtual-experience/design/fonts/Antenna-Thin.otf")
    format("opentype");
  font-weight: 100;
}

body {
  margin: 0;
  background-color: #03202f;
  font-family: Antenna;
  color: white;
}

h1 {
  font-family: Antenna;
  font-weight: 400;
  font-size: 5vw;
  justify-self: center;
  align-self: center;
  text-align: start;
  font-weight: normal;
}

h2 {
  font-family: Antenna;
  font-size: 3vw;
  font-weight: 100;
  text-align: start;
  font-weight: normal;
}

h3 {
  font-family: Antenna;
  font-size: 2vw;
  font-weight: 400;
  text-align: start;
  font-weight: normal;
}

h4 {
  font-family: Antenna;
  font-size: 1vw;
  font-weight: 100;
  text-align: start;
  font-weight: normal;
}
